.close {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 1.25rem;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.loading-text {
  text-align: center;
  color: #000000;
  font-weight: 700;
  font-family: var(--theme-body-font);
}

.sm-progress {
  border-radius: 0px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
}
.sm-progress .sm-progressbar {
  border-radius: 0px;
  background-color: var(--theme-button-color);
}

.border-radius-top-left {
  border-top-left-radius: 10px;
}

.border-radius-top-right {
  border-top-right-radius: 10px;
}

.border-radius-bottom-left {
  border-bottom-left-radius: 10px;
}

.border-radius-bottom-right {
  border-bottom-right-radius: 10px;
}

.navbar {
  padding-bottom: 0px;
  justify-content: flex-start !important;
}
.navbar .nav-content {
  width: 100%;
  background-color: var(--theme-nav-bg-col);
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.navbar .nav-content .navbar-bg {
  margin-left: 0;
  /*background-color: var(--theme-nav-bg-col);*/
  font-family: var(--theme-body-font);
  width: 100%;
  max-width: var(--theme-content-maxWidth);
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg {
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg {
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .navbar-nav {
    justify-content: center !important;
    --bs-nav-link-padding-y: 0rem;
  }
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .navbar-nav {
    justify-content: center !important;
    --bs-nav-link-padding-y: 0rem;
  }
}
.navbar .nav-content .navbar-bg .nav-item {
  width: auto;
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item {
    width: 33%;
  }
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item {
    width: 33%;
  }
}
.navbar .nav-content .navbar-bg .nav-item .nav-link {
  color: var(--theme-nav-text-col);
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem;
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.navbar .nav-content .navbar-bg .nav-item .nav-link.active {
  color: var(--theme-nav-text-selected-col);
  font-family: var(--theme-body-font-bold);
}
.navbar .nav-content .navbar-bg .nav-item .nav-link.active .nav-text {
  border-bottom: 2px solid var(--theme-nav-text-selected-col);
  width: 100%;
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link.active .nav-icon .icon-default {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link.active .nav-icon .icon-default {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link.active .nav-icon .icon-active {
    display: inline-block;
  }
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link.active .nav-icon .icon-active {
    display: inline-block;
  }
}
.navbar .nav-content .navbar-bg .nav-item .nav-link .nav-text {
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link .nav-text {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link .nav-text {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon {
  text-align: center;
  padding-bottom: 1px;
}
.navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon .icon-default {
  width: 24px;
  height: 24px;
  display: none;
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon .icon-default {
    display: inline-block;
  }
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon .icon-default {
    display: inline-block;
  }
}
.navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon .icon-active {
  width: 24px;
  height: 24px;
  display: none;
}
@media only screen and (max-width: 820px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon .icon-active {
    display: none;
  }
}
@media screen and (orientation: portrait) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link .nav-icon .icon-active {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .navbar .nav-content .navbar-bg .nav-item .nav-link {
    font-size: 0.8rem;
  }
}

.register {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
}
.register .form {
  padding: 30px 10px;
  height: auto;
  font-family: var(--theme-body-font);
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 1rem;
}
.register .form input {
  width: 300px;
  margin: 3px;
  border-radius: 4px;
  padding: 6px;
  color: #000000;
  border: 1px solid #000000;
}
.register .form input[type=button] {
  margin: 3px;
  background: var(--theme-button-color2);
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  color: #000000;
  font-weight: bold;
  width: 30%;
}
.register .form .error-message {
  color: #bd0000;
}
.register .form .text-link {
  text-decoration: none;
  color: #bd0000;
  font-weight: bold;
}
.register .form .logo {
  width: 40%;
  padding-bottom: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -moz-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -ms-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -o-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
}

.sm-logo {
  width: auto;
  height: 25px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 0px;
}
@media screen and (orientation: portrait) {
  .sm-logo {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .sm-logo {
    display: none;
  }
}

.sky-logo {
  width: auto;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 20px;
  display: block;
}
@media screen and (orientation: portrait) {
  .sky-logo {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .sky-logo {
    display: none;
  }
}

.reelrivals-logo {
  width: auto;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: block;
  position: relative;
}
@media screen and (orientation: portrait) {
  .reelrivals-logo {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .reelrivals-logo {
    display: none;
  }
}

.reelrivals-logo-port {
  width: auto;
  height: 50px;
  left: 8px;
  top: 70px;
  position: absolute;
  display: none;
  /*@media screen and (orientation:portrait){
      display:block;
  }
  @media only screen and (max-width: 820px) {
      display:block;
  }*/
}

.sm-logo-small {
  width: auto;
  height: 30px;
}

.sm-username {
  color: #000000;
  font-family: var(--theme-body-font);
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  font-size: 14px;
}

.footer-content {
  display: none;
  padding-bottom: 32px;
}
@media screen and (orientation: portrait) {
  .footer-content {
    display: block;
  }
}
@media only screen and (max-width: 820px) {
  .footer-content {
    display: block;
  }
}
.footer-content .sm-logo-footer {
  width: auto;
  height: 25px;
}
.footer-content .sm-username {
  text-align: center;
}

.main-content .lobby-content {
  display: flex;
}
@media only screen and (max-width: 820px) {
  .main-content .lobby-content {
    display: block;
  }
}
.main-content .right-column {
  font-family: var(--theme-body-font);
  width: 40%;
  display: block;
}
@media screen and (orientation: portrait) {
  .main-content .right-column {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .main-content .right-column {
    width: 40%;
  }
}
@media only screen and (max-width: 1000px) {
  .main-content .right-column {
    width: 45%;
  }
}
@media only screen and (max-width: 820px) {
  .main-content .right-column {
    width: 100%;
    /*padding-left:10px;
    padding-right:10px;*/
  }
}
.main-content .center-column {
  width: 60%;
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (orientation: portrait) {
  .main-content .center-column {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .main-content .center-column {
    width: 60%;
  }
}
@media only screen and (max-width: 1000px) {
  .main-content .center-column {
    width: 55%;
  }
}
@media only screen and (max-width: 820px) {
  .main-content .center-column {
    width: 100%;
  }
}
.main-content .portrait-section {
  font-family: var(--theme-body-font);
  display: none;
}
@media screen and (orientation: portrait) {
  .main-content .portrait-section {
    display: flex;
  }
}

.topbar-content {
  width: 100%;
  background-color: var(--theme-nav-bg-col);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding-bottom: 0px;
  margin-bottom: 10px;
}
@media screen and (orientation: portrait) {
  .topbar-content {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .topbar-content {
    margin-bottom: 0px;
  }
}
.topbar-content .topbar-content-container {
  padding-left: 0px;
  padding-right: 0px;
}
.topbar-content .right-column {
  font-family: var(--theme-body-font);
  width: 30%;
  display: block;
}
@media screen and (orientation: portrait) {
  .topbar-content .right-column {
    width: 0;
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .topbar-content .right-column {
    width: 0;
    display: none;
  }
}
.topbar-content .center-column {
  width: 70%;
}
@media screen and (orientation: portrait) {
  .topbar-content .center-column {
    width: 100%;
  }
}
@media only screen and (max-width: 820px) {
  .topbar-content .center-column {
    width: 100%;
  }
}

.tournament-card {
  /*max-width: var(--theme-content-maxWidth);*/
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: var(--theme-primary-color);
  color: #ffffff;
  font-family: var(--theme-body-font);
  margin-top: 8px;
  filter: none;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
}
@media screen and (orientation: portrait) {
  .tournament-card {
    margin-top: 0px;
    border-radius: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .tournament-card {
    margin-top: 0px;
    border-radius: 0px;
  }
}
.tournament-card.completed {
  background-color: var(--theme-primary-color);
  background: linear-gradient(0deg, var(--theme-primary-color) 0%, var(--theme-light-color) 35%, var(--theme-primary-color) 100%);
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.tournament-card.upcoming {
  background-color: var(--theme-primary-color);
  background: linear-gradient(0deg, var(--theme-primary-color) 0%, var(--theme-light-color) 35%, var(--theme-primary-color) 100%);
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.tournament-card .card-mainheader {
  display: flex;
  background-color: #212528;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 15px;
}
.tournament-card .card-mainheader .card-mainNav {
  text-align: center;
  font-weight: 600;
  padding-top: 7px;
  padding-bottom: 7px;
  display: block;
  cursor: pointer;
}
.tournament-card .card-mainheader .card-mainNav.disable {
  color: var(--theme-disabled-color);
}
.tournament-card .card-maintitle {
  text-align: center;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-primary-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-family: var(--theme-body-font);
}
.tournament-card .card-body {
  padding: 10px;
}
.tournament-card .card-body .completedIcon {
  width: 20px;
  padding-left: 2px;
}
.tournament-card .card-body .card-image {
  /*width:100%;
  max-width: 320px;
  padding-top: 50px;
  padding-bottom: 30px;*/
  width: auto;
  max-width: 320px;
  padding-top: 50px;
  padding-bottom: 30px;
  max-height: 200px;
}
.tournament-card .card-body .card-heading {
  font-size: 1rem;
  line-height: 1rem;
}
.tournament-card .card-body .card-detail {
  /* text-align: right; */
  display: inline-block;
  border-radius: 30px;
  background: var(--base-opacity-dark-75, rgba(26, 26, 26, 0.75));
  padding: 5px 12px;
  font-size: 0.8rem;
  font-family: var(--theme-body-font);
}
.tournament-card .card-body .card-detail .card-value {
  font-family: var(--theme-body-font-bold);
}
.tournament-card .card-body .card-playButton {
  font-family: var(--theme-body-font-bold);
  font-size: 14px;
  line-height: 14px;
  padding: 12px 35px;
  cursor: pointer;
  background-color: var(--theme-button-color);
  background: linear-gradient(0deg, var(--theme-button-color2) 0%, var(--theme-button-color) 100%);
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background: var(--theme-button-color);
  width: fit-content;
  display: block;
  color: #1A1A1A;
  text-decoration: none;
  border-radius: var(--theme-button-radius);
}
.tournament-card .card-row {
  display: flex;
  flex-wrap: wrap;
}
.tournament-card .card-playButton {
  margin: auto;
}
.tournament-card .card-weapons {
  padding: 10px 10px;
}
.tournament-card .card-weapons .card-weapon-img {
  width: 90%;
}
.tournament-card .card-weapons .card-weapon-title {
  font-family: var(--theme-heading-font);
  font-size: 0.9rem;
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-weapons .card-weapon-title {
    font-size: 0.8rem;
  }
}
.tournament-card .card-weapons .card-weapon-container {
  position: relative;
  text-align: center;
}
.tournament-card .card-weapons .card-weapon-usage {
  font-family: var(--theme-heading-font);
  position: absolute;
  bottom: 1px;
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-weapons .card-weapon-usage {
    font-size: 0.7rem;
    bottom: -3px;
  }
}
.tournament-card .card-weapons .infinitySymbol {
  filter: invert(100%) sepia(0%) saturate(31%) hue-rotate(140deg) brightness(106%) contrast(108%);
  width: 16px;
}
@media only screen and (max-width: 768px) {
  .tournament-card .card-weapons .infinitySymbol {
    width: 12px;
  }
}
.tournament-card .card-weapons .card-weapon-usage-top {
  top: 2px;
}
@media only screen and (max-width: 768px) {
  .tournament-card .card-weapons .card-weapon-usage-top {
    font-size: 0.6rem;
    top: 1px;
  }
}
.tournament-card .card-section-expand {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 42.71%);
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
@media screen and (orientation: portrait) {
  .tournament-card .card-section-expand {
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .tournament-card .card-section-expand {
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
  }
}
.tournament-card .card-section-expand .card-banner {
  cursor: pointer;
  background-image: none;
  background-color: var(--theme-secondary-color);
  padding: 10px 20px;
}
.tournament-card .card-section-expand .card-banner .card-title {
  font-size: 1.4rem;
}
.tournament-card .card-section-expand .card-banner.white {
  border-top: 4px solid;
  border-top-color: #ffffff;
}
.tournament-card .card-section-expand .card-desc {
  font-family: var(--theme-body-font-bold);
}
.tournament-card .card-section-expand .card-desc .card-desc-title {
  font-size: 0.7rem;
  line-height: 0.7rem;
}
.tournament-card .card-section-expand .card-desc .card-desc-text {
  font-size: 1rem;
  line-height: 1rem;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-section-expand .card-desc .card-desc-text {
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
}
.tournament-card .card-section-expand .card-section-title {
  font-family: var(--theme-heading-font);
  font-size: 20px;
}
.tournament-card .card-section-expand .card-section-desc {
  font-family: var(--theme-body-font);
  font-size: 16px;
}
.tournament-card .card-section-expand .weapon-title {
  font-size: 0.9rem;
  line-height: 0.9rem;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-section-expand .weapon-title {
    padding-left: 10px;
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
}
.tournament-card .entain-table-sm-icons {
  vertical-align: middle;
  width: 25px;
}
.tournament-card .white-icon {
  filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -webkit-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -moz-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -ms-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -o-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
}
.tournament-card .countdown-timer {
  color: #545454;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
}
.tournament-card .countdown-timer .starts-text {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1rem;
  line-height: 1.1rem;
}

.show-counter .countdown-link {
  justify-content: center;
}

.card-arrow-sky {
  text-align: center;
  padding-top: 12px;
}

.card-arrow {
  text-align: right;
  height: 100%;
  width: 20px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.card-arrow.down {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}
.card-arrow.left {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}
.card-arrow.right {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.mytournamentsHeader {
  font-family: var(--theme-body-font-bold);
  text-align: center;
  padding: 16px;
}

.container-page {
  width: 100%;
  max-width: var(--theme-content-maxWidth);
  border-radius: 8px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  font-family: var(--theme-body-font);
  background-color: #ffffff;
  padding: 0px;
  margin-bottom: 32px;
}
@media screen and (orientation: portrait) {
  .container-page {
    margin-top: 8px;
    width: 96%;
  }
}
@media only screen and (max-width: 820px) {
  .container-page {
    margin-top: 8px;
    width: 96%;
  }
}
.container-page .text-link {
  text-decoration: none;
  color: var(--theme-secondary-color);
}
.container-page .content-page h3 {
  font-family: var(--theme-body-font-bold);
  padding-bottom: 10px;
  text-align: center;
}
.container-page .content-page .page-header {
  font-family: var(--theme-body-font);
  font-size: 1.1rem;
}
.container-page .content-page .page-desc {
  padding-bottom: 20px;
}
.container-page .content-page .page-button {
  font-family: var(--theme-body-font);
  padding: 1px;
  background-color: var(--theme-button-color);
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  width: auto;
  display: block;
  color: #000000;
  text-decoration: none;
}
.container-page .content-page #tutorialsPlayer {
  width: 100%;
}

.carousel-container {
  position: relative;
}
.carousel-container .carousel-holder {
  overflow: hidden;
  width: 90%;
  margin-left: 5%;
}
.carousel-container .left-arrow {
  padding-top: 10%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  height: auto;
}
.carousel-container .left-arrow.top {
  padding-top: 5%;
}
.carousel-container .right-arrow {
  padding-top: 10%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  height: auto;
}
.carousel-container .right-arrow.top {
  padding-top: 5%;
}

.card-table {
  background-color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 0px;
  color: #000000;
  font-family: var(--theme-body-font-bold);
}
.card-table .small {
  font-size: 12px;
  color: #545454;
  font-family: var(--theme-body-font);
}
.card-table .positionIcon {
  height: 32px;
}
.card-table .position-text {
  font-size: 18px;
}
.card-table .table-row:nth-child(odd) {
  background-color: var(--theme-table-row-odd);
}
.card-table .table-row.highlight {
  font-family: var(--theme-heading-font);
  font-size: 16px;
  line-height: 18px;
  background-color: var(--theme-table-row-highlight);
}
.card-table .table-row.highlight .small {
  font-size: 14px;
  font-family: var(--theme-body-font-bold);
}
.card-table .table-row.title {
  background-color: #C6C6C6;
}
.card-table .table-row.title .table-col div {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--theme-heading-font);
  color: #000000;
}
.card-table .table-row.title .table-col div .small {
  font-family: var(--theme-body-font-bold);
}
.card-table .table-col {
  margin-top: auto;
  margin-bottom: auto;
}
.card-table .table-col div {
  height: 100%;
}

.leaderboard-side {
  /*max-width: var(--theme-content-maxWidth);*/
  padding-bottom: 32px;
  /*.player-name-text{
      color: #000000;
      font-family: var(--theme-body-font);
      font-size: 1rem;
      line-height: 1.2rem;
  }   */
}
@media screen and (orientation: portrait) {
  .leaderboard-side {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 820px) {
  .leaderboard-side {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.leaderboard-side .card-banner {
  padding: 0px;
}
.leaderboard-side .card-banner .nav-head {
  border-radius: 20px;
  color: #ffffff;
  background-color: var(--theme-secondary-color);
  margin-bottom: 5px;
}
.leaderboard-side .card-banner .nav-head .nav-link {
  padding: 5px 15px;
  border-radius: 20px;
  color: #ffffff;
  background-color: var(--theme-secondary-color);
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
}
.leaderboard-side .card-banner .nav-head .nav-link.selected {
  background-color: var(--theme-primary-color);
}
.leaderboard-side .leaderboard-size-content-area {
  max-height: 205px;
  overflow-y: scroll;
  height: 205px;
}
.leaderboard-side .leaderboard-size-content-area.noHighlight {
  max-height: 255px;
  overflow-y: scroll;
  height: 255px;
}

.leaderboard-footer {
  background-color: #ffffff;
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tounament-nav {
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  font-family: var(--theme-body-font-bold);
  font-size: 18px;
  line-height: 22px;
}
.tounament-nav .date-text {
  font-family: var(--theme-body-font);
  color: #1A1A1A;
  font-size: 14px;
}

.schedule-nav {
  font-size: 14px;
  font-family: var(--theme-body-font);
}
.schedule-nav .schedule-item {
  background-color: #ffffff;
  padding: 12px 8px;
  border-radius: 8px;
  height: 100%;
}
.schedule-nav .schedule-item.previous {
  background-color: #E8E8E8;
}
.schedule-nav .schedule-item .game-name {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden;
  font-family: var(--theme-body-font-bold);
}
.schedule-nav .schedule-item .game-date {
  color: #1A1A1A;
}
.schedule-nav .schedule-item .schedule-now {
  color: #D80A00;
  font-weight: 700;
  font-family: var(--theme-body-font-bold);
}
.schedule-nav .schedule-item .schedule-prev {
  color: #8C8C8C;
  font-family: var(--theme-body-font-bold);
}
.schedule-nav .schedule-item .schedule-comingup {
  color: #519EFB;
  font-family: var(--theme-body-font-bold);
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.7rem;
  /*justify-content: center;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  color: #000;
  border-radius: 0.25rem;*/
  text-decoration: none;
}
@media only screen and (max-width: 576px) {
  .show-counter .countdown-link {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 2px 0 2px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media only screen and (max-width: 576px) {
  .show-counter .countdown > span {
    font-size: 0.5rem;
    line-height: 0.9rem;
  }
}

.popupContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.popupContainer .contentContainer {
  border-radius: 10px;
  width: 320px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 10px;
  left: 50%;
  margin-top: 10px;
  margin-left: -160px;
}
.popupContainer .contentContainer .content {
  padding: 40px 20px;
  text-align: center;
  font-size: 0.9rem;
  font-family: "Arial";
  color: #000000;
}
.popupContainer .contentContainer .content .buttons {
  margin-top: 20px;
}
.popupContainer .contentContainer .content .buttons .ctabtn {
  padding: 3px;
  margin: 5px;
  display: block;
  max-width: 100%;
  color: #ffffff;
  background-color: #8bbd23;
  cursor: pointer;
  border-radius: 2px;
  background-image: linear-gradient(to bottom, #8bbd23, #8bbd23);
}
.popupContainer .contentContainer .content .buttons .ctabtn :hover {
  cursor: pointer;
  background-image: linear-gradient(to top, #8bbd23, #8bbd23);
}

@font-face {
  font-family: "sky_textmedium";
  src: url("../fonts/skytextmedium-regular_586471505-webfont.eot");
  src: url("../fonts/skytextmedium-regular_586471505-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/skytextmedium-regular_586471505-webfont.woff2") format("woff2"), url("../fonts/skytextmedium-regular_586471505-webfont.woff") format("woff"), url("../fonts/skytextmedium-regular_586471505-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sky_textregular";
  src: url("../fonts/skytext-regular_3650438634-webfont.eot");
  src: url("../fonts/skytext-regular_3650438634-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/skytext-regular_3650438634-webfont.woff2") format("woff2"), url("../fonts/skytext-regular_3650438634-webfont.woff") format("woff"), url("../fonts/skytext-regular_3650438634-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sky_textitalic";
  src: url("../fonts/skytext-italic_2100815725-webfont.eot");
  src: url("../fonts/skytext-italic_2100815725-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/skytext-italic_2100815725-webfont.woff2") format("woff2"), url("../fonts/skytext-italic_2100815725-webfont.woff") format("woff"), url("../fonts/skytext-italic_2100815725-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sky_bold";
  src: url("../fonts/skytext-bold_1830760342-webfont.eot");
  src: url("../fonts/skytext-bold_1830760342-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/skytext-bold_1830760342-webfont.woff2") format("woff2"), url("../fonts/skytext-bold_1830760342-webfont.woff") format("woff"), url("../fonts/skytext-bold_1830760342-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*@font-face {
    font-family: 'sky_textregular';
    src: url('../fonts/sky-text-regular-webfont.eot');
    src: url('../fonts/sky-text-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/sky-text-regular-webfont.woff2') format('woff2'),
         url('../fonts/sky-text-regular-webfont.woff') format('woff'),
         url('../fonts/sky-text-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sky_bold';
    src: url('../fonts/skybold-webfont.woff');
    src: url('../fonts/skybold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}*/
:root {
  --theme-heading-font:sky_bold;
  --theme-body-font:sky_textregular;
  --theme-body-font-bold:sky_textmedium;
  --theme-body-bg:url("../../images/bg.png");
  --theme-primary-color:#bd0000;
  --theme-light-color:#ff2a2c;
  --theme-dark-color:#691314;
  --theme-dark-color2:#bd0000;
  --theme-secondary-color:#413d3c;
  --theme-font-color:#ffffff;
  --theme-selected-color:#FF9C00;
  --theme-button-color:#FF9C00;
  --theme-button-color2:#FF9C00;
  --theme-button-radius:5px;
  --theme-disabled-color: rgba(255,255,255,0.5);
  --theme-nav-bg-col:#ffffff;
  --theme-nav-text-col:#545454;
  --theme-nav-text-selected-col:#D80A00;
  --theme-completed-color:#691314;
  --theme-completed-color2:#bd0000;
  --theme-upcoming-color:#05375f;
  --theme-upcoming-color2:#065aa1;
  --theme-table-row:#F4F4F4;
  --theme-table-row-odd:#E8E8E8;
  --theme-table-row-highlight:#FFE6BF;
  --theme-table-player-name-col:#ffc446;
  --theme-table-font-colour:#ffffff;
  --bs-body-bg: null;
  --bs-body-font-family:--theme-body-font;
  --theme-content-maxWidth: 600px;
}

html {
  height: 100%;
  margin: 0;
}

body {
  min-height: 100%;
  margin: 0;
  background-color: #252525;
  /*background-color:#e1e1e1;
  background-color:#f5f5f5;*/
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
